<template>
	<div class="page">
		<Nav/>
		<Banner/>
		<div class="container">
			<el-breadcrumb separator="/" class="top">
				<el-breadcrumb-item :to="{ path: '/' }">首頁</el-breadcrumb-item>
				<el-breadcrumb-item>關於我們</el-breadcrumb-item>
			</el-breadcrumb>
			<el-card class="top">
				<div class="left" style="text-align: center;">
					<img class="top5" style="width: 150px; height: 150px;" src="../../assets/images/weChat-1.jpg"/>
					<p style="width: 70%;margin: 20px auto;line-height: 30px;">
						我們是壹家在線遊戲公司，專注于高質量娛樂內容的開發、運營和分銷，包括手機遊戲和網絡遊戲。同時，我們運營網絡遊戲平台和移動應用分發平台。我們致力于擴大海外業務，利用我們卓越的研發能力和市場拓展技能，推動公司業績取得突破。我們堅持“注重質量，用心服務”的經營理念，以用戶價值爲核心。我們追求卓越的遊戲體驗，強調遊戲産品的持續盈利能力。我們堅持“聚焦遊戲核心，研發、分銷、投資+IP領先”的發展戰略這壹戰略旨在建立壹個多維度、立體化的研發和分銷體系。在全球範圍內，我們尋找和引進優質IP，深入研究IP的品牌潛力，努力探索和提升IP的品牌價值。憑借強大的技術積累和優秀的研發團隊，我們不斷拓展産品線，創造出新壹代優秀作品。目前，我們強大的IP獲取和創作能力已成爲公司的核心競爭優勢之壹，爲新作品的開發提供了強有力的支持。我們的手機遊戲改編自端遊IP，是成功將端遊IP改編爲手機遊戲的先驅。我們開發並運營了多個流行遊戲，涵蓋各種遊戲類型，如角色扮演、模擬、休閑比賽和策略。我們堅持把“聚焦遊戲核心”作爲公司發展的核心戰略方向。我們的目標是滿足市場需求，通過研發、分銷和投資+IP爲用戶提供高質量的內容和深度的遊戲娛樂體驗。我們致力于打造壹家卓越的在線遊戲公司。
					</p>
				</div>
			</el-card>
		</div>
		<Footer class="top"/>
	</div>
</template>

<script>
import Banner from './components/Banner'
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'

export default {
    name: 'About',
    components: {Nav, Footer, Banner},

    data() {
        return {};
    },


    mounted() {

    },

    methods: {},
};
</script>

<style lang="scss" scoped>

</style>
